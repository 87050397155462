import { BehaviorSubject } from 'rxjs';
import { ApiDataSource } from '../api.data-source';
import { debounceTime, finalize, tap } from 'rxjs/operators';
import { CustomerExperienceUnwantedPhrase, CustomerExperienceUnwantedPhraseService } from '@core/api';

export class CustomerExperienceUnwantedPhraseDataSource extends ApiDataSource<CustomerExperienceUnwantedPhrase> {

    public isOpenedNewTab = false;

    public request$ = new BehaviorSubject<{}>(null);

    constructor(
        private unwantedPhraseService: CustomerExperienceUnwantedPhraseService,
        protected initialFilter?: any,
    ) {
        super(initialFilter);
    }

    load(): void {
        if (!this.isOpenedNewTab) {
            // Init filter with data source's default filter
            const filterValue: any = { ...this.initialFilter, ...this.filter };

            // If filter keyword exists, filter data
            if (this.keyword) {
                filterValue.searchText = this.keyword;
            }

            // Update loading state
            this.loadingSubject.next(true);

            // Create request parameters
            const request = this.getRequest();
            if (!request.orderType) {
                request.orderType = 'ASC';
                request.orderBy = 'createdOn';
            }

            if (this.paginator?.pageSize) {
                request.pageSize = this.paginator.pageSize;
            }

            // Add filters to request
            request.filter = filterValue;

            // Fetch data
            this.unwantedPhraseService
                .search(request)
                .pipe(
                    tap(() => this.request$.next(request)),
                    debounceTime(400),
                    finalize(() => this.loadingSubject.next(false))
                ).subscribe(response => {
                    // Update count and data subjects
                    this.dataSubject.next(response.data.results);
                    this.dataCountSubject.next(response.data.rowCount);

                    // Update data source's empty based row count
                    this.empty = response.data.rowCount === 0;
                });
        }

        return this.loadingSubject.next(false);
    }
}
