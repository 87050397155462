import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { OpportunityOfferMail, OpportunityOfferMailRespondRequest } from './opportunity-offer-mail.model';
import { Observable } from 'rxjs';
import { Response } from '../api.model';
import { createFileUrl } from '@shared/utils';

@Injectable()
export class OpportunityOfferMailService {
    private readonly http = inject(HttpClient);

    get(opportunityOfferMailSentId: string): Observable<Response<OpportunityOfferMail>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<OpportunityOfferMail>>
            (environment.apiUrl + '/api/OpportunityOfferMail/Get', JSON.stringify(opportunityOfferMailSentId), { headers });
    }

    respond(request: OpportunityOfferMailRespondRequest): Observable<Response<OpportunityOfferMail>> {
        return this.http.post<Response<OpportunityOfferMail>>(environment.apiUrl + '/api/OpportunityOfferMail/Respond', request);
    }

    showOfferMailPdf(opportunityOfferMailSentId: string) {
        return createFileUrl(environment.apiUrl + `/api/OpportunityOfferMail/ShowOfferMailPdf?id=${opportunityOfferMailSentId}`);
    }
}
