import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class AccountHistoryService {
    private refreshAcocuntHistory = new BehaviorSubject<boolean>(false);
    refreshAccountHistory$ = this.refreshAcocuntHistory.asObservable();

    setRefreshAccountHistory(value: boolean) {
        this.refreshAcocuntHistory.next(value);
    }
}
