export enum SalesOrganizationAdvancedSettingType {
  CROSS_SELL = 'crossSale',
  OFFER = 'offer',
  OPPORTUNITY_TASK_USAGE = 'opportunityTask',
  COORDINATOR_AND_REGION = 'customerRegion',
  CLIENT_COMPANIES = 'customerOrganization',
  ACTIVITY_MAIL = 'activityEmail',
  // opportunityAdvanced === false ? SalesOrganizationSettingValueType.BASIC : SalesOrganizationSettingValueType.DETAILED
  OPPORTUNITY_PROCESS = 'opportunityAdvanced',
  MONTHLY_REVENUE_GRAPH = 'revenueGraph',
  OPPORTUNITY_OFFER = 'opportunityPdf',
  ACCOUNT_SOURCE = 'customerSource',
  OPPORTUNITY_COPY_SUMMARY_USAGE = 'opportunitySummary',
  OPPORTUNITY_GENERAL_CONDITIONS = 'opportunityCondition',
  SENDING_MAIL_WITH_OPPORTUNITY = 'opportunityEmail',
  CUSTOMER_SEGMENT_USAGE = 'customerSegment',
  OPPORTUNITY_APPROVAL_PROCESS_USAGE = 'opportunityApprovalProcess',
  OPPORTUNITY_PRICE_LISTS_LEVEL_SELECTION = 'opportunityPriceListsLevel',
  CONSUMER_ACCOUNT_TYPE_IDS = 'consumerAccountTypeIds'
}

export enum SalesOrganizationAdvancedSettingTypeAsString {
  CROSS_SELL = '96e4d597-2d2d-4415-9957-ad912dd1e24f',
  OFFER = '289d49ef-d41d-48dd-9d4a-6778bc1f0a0c',
  OPPORTUNITY_TASK_USAGE = 'a680fdb9-e490-4c82-a36a-ee205f1c79cd',
  COORDINATOR_AND_REGION = 'a5d3e424-1d1e-4e36-8775-513085e715f0',
  CLIENT_COMPANIES = 'c2b6526c-f3a7-45fb-822e-42b9309803b0',
  ACTIVITY_MAIL = '116d4fc8-9b79-47ea-8219-e0c8f99e2d1c',
  OPPORTUNITY_PROCESS = '2cf420f0-b19c-4131-bb43-2dbd7cbe27e7',
  MONTHLY_REVENUE_GRAPH = 'c49e26a9-cdec-4654-81fc-ac2a164b94cd',
  OPPORTUNITY_OFFER = '7b1d9567-5e58-48fc-985c-53273fbe1b02',
  ACCOUNT_SOURCE = '918668b9-3d24-4b5e-a7fc-073f72a123fa',
  OPPORTUNITY_COPY_SUMMARY_USAGE = '8ef41036-08c0-4a32-940b-0f2d95f83724',
  OPPORTUNITY_GENERAL_CONDITIONS = '60c1a513-37db-4a52-a4f6-b1906e029a7d',
  SENDING_MAIL_WITH_OPPORTUNITY = '04b9b527-a530-43f2-9e65-73908edff36e',
  CUSTOMER_SEGMENT_USAGE = '6bd53f8a-a376-404f-b2d4-8d40d48ed431',
  OPPORTUNITY_APPROVAL_PROCESS_USAGE = 'c4ebcf26-1f51-4393-b4a8-df9ba90540a8',
  OPPORTUNITY_PRICE_LISTS_LEVEL_SELECTION = '3b29c72c-59fc-4f00-a8b5-55983ca0f357',
  CONSUMER_ACCOUNT_TYPE_IDS = '823b1e93-81ad-4941-a927-f6c174962881',
  OPPORTUNITY_OFFER_MAIL_ACTIONS = '873bbac1-e58a-4923-b4df-f6a704d25805'
}

export enum SalesOrganizationActivitySettingType {
  LEAD_DRAFT_ACTIVITY_USAGE = 'leadDraftActivity'
}

export enum SalesOrganizationActivitySettingTypeAsString {
  LEAD_DRAFT_ACTIVITY_USAGE = '8939b423-400a-42d8-9b23-4fe3a117c97b'
}

export enum SalesOrganizationSettingControlTypes {
  BOOLEAN = 'boolean',
  INTEGER = 'integer',
  TEXT = 'text',
  SINGLE = 'single',
  MULTIPLE = 'multiple',
  DATE = 'date',
  PROPERTY = 'property'
}

export enum SalesOrganizationSettingType {
  ADVANCED = 0,
  ACTIVITY = 1
}
