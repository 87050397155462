<input class="currency-input" #input matInput [matTooltip]="tooltip" [tabIndex]="tabIndex" [formControl]="inputCtrl"
  [matAutocomplete]="auto" [placeholder]="placeholder" [matTooltipDisabled]="!!this.selectedCurrency" (blur)="onBlur()"
  netFormChanged entityPrimaryKey="transactionCurrencyId">
<net-iconify-icon class="dropdown-icon" [icon]="icArrowDropDown" matSuffix
  (click)="openPanel($event)"></net-iconify-icon>
<mat-autocomplete #auto="matAutocomplete" panelWidth="auto" [displayWith]="displayName" [autoActiveFirstOption]="true"
  [matTooltip]="tooltip" matTooltipPosition="above" (optionSelected)="onSelectionChange($event)" (closed)="onClosed()">
  <mat-option *ngFor="let currency of filteredCurrencies" [value]="currency.transactionCurrencyId">
    {{('TransactionCurrency.' + currency.currencyName) | translate}} - {{currency.isoCurrencyCode}}
  </mat-option>
</mat-autocomplete>