import { finalize, map, switchMap, tap } from 'rxjs/operators';

import {
  AccountStatusService,
  ApiService,
  Contact,
  ContactFilterRequest,
  Guid,
  LeadDraftInfoStatusService,
  PagedResponse,
  SalesOrganizationService,
  SalesRouteDefinitionService,
  SystemUserService,
  User
} from '@core/api';
import { ApiDataSource } from '@core/api/api.data-source';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';

export class ContactDataSource extends ApiDataSource<Contact> {

  public isOpenedNewTab = false;
  public request$ = new BehaviorSubject<{}>(null);

  public $createdBys = of(null).pipe(
    switchMap(() => {
      return this.apiService.contact
        .listCreatedUsers({
          filter: {
            salesOrganizationIds: this.user.salesOrganizations.map(item => item),
            ...this.filter?.permissionControl === false ? { leadContact: true } : {}
          }
        }).pipe(
          map(response => response.data),
          map(statuses => statuses.map(item => {
            return {
              key: item.userId,
              value: item.displayName,
            };
          }))
        );
    })
  );

  public $routeOwners = of(null).pipe(
    switchMap(() => {
      return this.apiService.contact
        .listRouteOwners({
          filter: {
            salesOrganizationIds: this.user.salesOrganizations.map(item => item),
            ...this.filter?.permissionControl === false ? { leadContact: true } : {}
          }
        }).pipe(
          map(response => response.data),
          map(routeOwners => routeOwners.map(item => {
            return {
              key: item.userId,
              value: item.displayName,
            };
          })),
          map(routeOwners => {
            routeOwners.unshift({
              key: Guid.EMPTY,
              value: this.translate.instant('GENERAL.UNDEFINED')
            });
            return routeOwners;
          })
        );
    })
  );

  public $contactTypes = this.apiService.contactRoleGroup.list().pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      const translatedStatus = this.translate.instant('ContactRoleGroup.' + item.name);
      return {
        key: item.contactRoleGroupId,
        value: translatedStatus,
      };
    }))
  );

  public $salesOrganizations = this.salesOrganizationService.search({ systemUserId: this.user.userId }).pipe(
    map(response => response.data),
    map(statuses => statuses.sort((a, b) => (a.name > b.name) ? 1 : -1).map(item => {
      return {
        key: item.salesOrganizationId,
        value: item.name
      };
    }))
  );

  public $accountStatuses = this.accountStatusService.filterSearch().pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      const translatedStatus = this.translate.instant('AccountStatus.' + item.name);
      return {
        key: { accountStatusId: item.accountStatusId, salesOrganizationId: item.salesOrganization?.salesOrganizationId },
        // tslint:disable-next-line:max-line-length
        value: item.salesOrganization && item.salesOrganization?.shortName ? `${translatedStatus} - (${item.salesOrganization.shortName})` : translatedStatus,
      };
    }))
  );

  public $leadDraftInfoStatus = this.leadDraftInfoStatusService.list({}).pipe(
    map(response => response.data),
    map(statuses => statuses.map(item => {
      return {
        key: item.leadDraftInfoStatusId,
        value: this.translate.instant('LeadDraftInfoStatus.' + item.name)
      };
    }))
  );

  public $salesRoutes = this.salesRouteDefinitionService.userRoutes().pipe(
    map(response => response.data.sort((a, b) => a.name.localeCompare(b.name))),
    map(route => route.map(item => {
      return {
        key: item.salesRouteDefinitionId,
        value: item.name
      };
    })),
    map(routes => {
      routes.unshift({
        key: Guid.EMPTY,
        value: this.translate.instant('GENERAL.UNDEFINED')
      });
      return routes;
    })
  );

  public $status = of([
    { key: 0, value: this.translate.instant('GENERAL.PASSIVE') },
    { key: 1, value: this.translate.instant('GENERAL.ACTIVE') }
  ]);

  constructor(
    private apiService: ApiService,
    private translate: TranslateService,
    private accountStatusService: AccountStatusService,
    private salesOrganizationService: SalesOrganizationService,
    private user: User,
    private leadDraftInfoStatusService: LeadDraftInfoStatusService,
    private salesRouteDefinitionService: SalesRouteDefinitionService,
    public initialFilter?: any) {
    super(initialFilter);
  }

  updateLoadingSubject(loading: boolean) {
    this.loadingSubject.next(loading);
  }

  load(): void {
    if (!this.isOpenedNewTab) {

      // Init filter with data source's default filter
      const filter: any = { ...this.initialFilter, ...this.filter };

      // If filter keyword exists, filter data
      if (this.keyword) {
        filter.searchText = this.keyword;
      }

      // Update loading state
      this.loadingSubject.next(true);

      // Create request parameters
      const request: ContactFilterRequest = this.getRequest();

      if (this.paginator?.pageSize) {
        request.pageSize = this.paginator.pageSize;
      }

      if (filter?.enabled === 0) {
        filter.enabled = false;
      }

      if (filter?.enabled === 1) {
        filter.enabled = true;
      }

      // Add filters to request
      request.filter = filter;
      let contactDataSource$: Observable<PagedResponse<Contact>>;

      if (request.filter?.permissionControl) {
        contactDataSource$ = this.apiService.contact.search(request);
      } else if (request.filter?.permissionControl === false) {
        contactDataSource$ = this.apiService.contact.listSearchLeadContact(request);
      } else {
        request.filter.permissionControl = true;
        contactDataSource$ = this.apiService.contact.listSearch(request);
      }
      // Fetch data
      contactDataSource$.pipe(
        tap(() => this.request$.next(request)),
        finalize(() => this.loadingSubject.next(false))
      )
        .subscribe(response => {
          // Update count and data subjects
          this.dataSubject.next(response.data?.results);
          this.dataCountSubject.next(response.data.rowCount);
          this.rowCount$.next(response.data.rowCount);

          // Update data source's empty based row count
          this.empty = response.data.rowCount === 0;
        });

    }
    return this.loadingSubject.next(false);
  }
}
