import {
    AfterViewInit, Directive, EventEmitter,
    HostListener, inject, Input, Optional, Output
} from '@angular/core';
import { NgControl } from '@angular/forms';
import { FormChangedService } from '@shared/services';
import { isEqual, cloneDeep } from 'lodash';
import { debounceTime, Subject } from 'rxjs';

@Directive({
    selector: '[netFormChanged]',
    standalone: true
})

export class FormChangedDirective implements AfterViewInit {

    private readonly ngControl: NgControl = inject(NgControl);

    private oldValue: any;
    private changeSubject = new Subject<void>();

    @Input() formControlName: string;
    @Input() formControl: string;
    @Input() netFormChanged: boolean;
    @Input() entityPrimaryKey: string;
    @Input() objectLoading: boolean;
    @Output() appFormDirtyChange = new EventEmitter<boolean>();

    @HostListener('ngModelChange', ['$event'])
    change() {
        if (this.objectLoading) {
            return;
        }
        this.changeSubject.next();
    }

    constructor(
        @Optional() private readonly formChangedService: FormChangedService
    ) {
        this.changeSubject.pipe(debounceTime(500)).subscribe(() => {
            if (this.ngControl && this.ngControl.control) {

                if (this.ngControl.control.disabled) {
                    return;
                }

                if (!this.entityPrimaryKey && !isEqual(this.oldValue, this.ngControl.value)) {
                    this.netFormChanged = true;
                    this.formChangedService.setFormChanged(this.netFormChanged);
                    return;
                }

                if (this.entityPrimaryKey && this.oldValue[this.entityPrimaryKey] !== this.ngControl.value[this.entityPrimaryKey]) {
                    this.netFormChanged = true;
                    this.formChangedService.setFormChanged(this.netFormChanged);
                    return;
                }
            }
        });
    }

    ngAfterViewInit(): void {
        this.oldValue = cloneDeep(this.ngControl.value);
    }

    setOldValue() {
        this.oldValue = cloneDeep(this.ngControl.value);
    }
}
