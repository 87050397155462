import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class FormChangedService {

    private formChanged = new BehaviorSubject<boolean>(false);
    private formChangedSignal = this.formChanged.asObservable();

    constructor() { }

    public setFormChanged(value: boolean): void {
        this.formChanged.next(value);
    }

    public getFormChanged(): Observable<boolean> {
        return this.formChangedSignal;
    }

}
