import { Component, inject } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import Swal from 'sweetalert2';
import {
  OpportunityOfferMail, OpportunityOfferMailActionType, OpportunityOfferMailDialogCloseModel,
  OpportunityOfferMailRespondRequest, OpportunityOfferMailService
} from '@core/api';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { OpportunityOfferMailDialogComponent } from './opportunity-offer-mail-dialog/opportunity-offer-mail-dialog.component';
import { ActivatedRoute } from '@angular/router';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { map, tap } from 'rxjs';
import moment from 'moment';

@Component({
  selector: 'net-opportunity-offer-mail',
  standalone: true,
  imports: [
    SharedModule,
    PdfViewerModule
  ],
  templateUrl: './opportunity-offer-mail.component.html',
  styleUrl: './opportunity-offer-mail.component.scss',
  providers: [OpportunityOfferMailService]
})
export class OpportunityOfferMailComponent {

  icClose = Icon.IC_CLOSE;
  icCheck = Icon.IC_CHECK;
  msRotateLeft = Icon.MATERIAL_SYMBOLS_ROTATE_LEFT;
  mdiWarningCircleOutline = Icon.MDI_WARNING_CIRCLE_OUTLINE;

  loading = true;
  pdfRendered = false;
  pdfUrl: string;
  opportunityOfferMail: OpportunityOfferMail;
  opportunityOfferMailActionType = OpportunityOfferMailActionType;

  private dialogRef: MatDialogRef<OpportunityOfferMailDialogComponent>;
  private opportunityOfferMailSentId: string;

  private readonly translate = inject(TranslateService);
  private readonly dialog = inject(MatDialog);
  private readonly opportunityOfferMailService = inject(OpportunityOfferMailService);
  private readonly route = inject(ActivatedRoute);

  constructor() {

    this.route.params.subscribe(params => {
      this.opportunityOfferMailSentId = params['opportunityOfferMailSentId'];
      this.getOpportunityOfferMail();
    });
  }

  accept() {
    Swal.fire({
      html: this.translate.instant('OPPORTUNITY_OFFER.ACCEPT_MESSAGE'),
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#4caf50',
      cancelButtonColor: '#7a7a7a',
      confirmButtonText: this.translate.instant('GENERAL.CONFIRM'),
      cancelButtonText: this.translate.instant('GENERAL.CANCEL')
    }).then((result) => {
      if (result.isConfirmed) {
        const data = {
          opportunityOfferMailActionTypeId: OpportunityOfferMailActionType.ACCEPTED,
          id: this.opportunityOfferMailSentId
        };

        this.opportunityOfferMailRespond(data);

      }
    });
  }

  reject() {
    const data = {
      type: OpportunityOfferMailActionType.REJECTED
    };

    this.openOpportunityOfferMailDialog(data);
  }

  requestRevision() {
    const data = {
      type: OpportunityOfferMailActionType.REQUESTED_REVISION
    };

    this.openOpportunityOfferMailDialog(data);
  }

  pageRendered(e: CustomEvent) {
    this.pdfRendered = true;
  }

  private getOpportunityOfferMail() {

    this.opportunityOfferMailService.get(this.opportunityOfferMailSentId)
      .pipe(
        map(response => response.data),
        tap(data => {
          this.setCulture(data.language);
          if (data.isExpired) {
            return;
          }
          this.getOpportunityOfferMailPdf();
        })
      ).subscribe((data) => {

        this.opportunityOfferMail = {
          ...data,
          actions: data.actions.map(action => {
            return {
              ...action,
              order: this.getActionOrder(action.opportunityOfferMailActionTypeId)
            };
          }).sort((a, b) => b.order - a.order)
        };

      }).add(() => this.loading = false);

  }

  private getActionOrder(actionType: OpportunityOfferMailActionType) {
    switch (actionType) {
      case OpportunityOfferMailActionType.ACCEPTED:
        return 1;
      case OpportunityOfferMailActionType.REQUESTED_REVISION:
        return 2;
      case OpportunityOfferMailActionType.REJECTED:
        return 3;
      default:
        return 0;
    }
  }

  private setCulture(language: string) {

    const culture = language ?? (navigator.language.slice(0, 2) === 'tr' ? 'tr' : 'en');
    this.translate.use(culture);
    moment.locale(culture.slice(0, 2));

  }

  private openOpportunityOfferMailDialog(data) {
    this.dialogRef = this.dialog.open(OpportunityOfferMailDialogComponent, {
      data, autoFocus: false
    }).addPanelClass('cdk-full-overlay');

    this.dialogRef.afterClosed().subscribe((result: OpportunityOfferMailDialogCloseModel | null) => {

      if (!result) {
        return;
      }

      const request: OpportunityOfferMailRespondRequest = {
        id: this.opportunityOfferMailSentId,
        opportunityOfferMailActionTypeId: result.type,
        description: result.description
      };

      this.opportunityOfferMailRespond(request);

    });
  }

  private opportunityOfferMailRespond(request: OpportunityOfferMailRespondRequest) {

    this.opportunityOfferMailService.respond(request).pipe(
      map(response => response.data)
    ).subscribe((response) => {
      this.opportunityOfferMail = {
        ...this.opportunityOfferMail,
        actions: [],
        respondedActionTypeId: response.respondedActionTypeId,
        respondedDate: response.respondedDate
      };
    });

  }

  private getOpportunityOfferMailPdf() {
    this.opportunityOfferMailService.showOfferMailPdf(this.opportunityOfferMailSentId).then((url) => {
      this.pdfUrl = url;
    });

  }

}
