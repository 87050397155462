import { Component, DestroyRef, inject, OnInit } from '@angular/core';
import { trackById } from 'src/app/shared/utils/track-by';
import { RecentPage } from './recent-pages.model';
import { stagger40ms } from 'src/app/shared/animations/stagger.animation';
import { Router } from '@angular/router';
import { PopoverRef } from 'src/app/shared/components/popover/popover-ref';
import { TranslateService } from '@ngx-translate/core';
import { BoardInfoSignalRService, RecentPageService } from '@core/api';
import { SharedModule } from '@shared/modules';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';

@Component({
  selector: 'net-recent-pages-dropdown',
  templateUrl: './recent-pages-dropdown.component.html',
  styleUrls: ['./recent-pages-dropdown.component.scss'],
  animations: [
    stagger40ms,
  ],
  standalone: true,
  imports: [
    SharedModule,
  ]
})
export class RecentPagesDropdownComponent implements OnInit {
  recentPages: RecentPage[];
  loading = true;
  trackById = trackById;

  translations: any;

  private readonly recentPageService = inject(RecentPageService);
  private readonly destroyRef = inject(DestroyRef);

  constructor(
    private router: Router,
    private boardInfoSignalRService: BoardInfoSignalRService,
    private popoverRef: PopoverRef<RecentPagesDropdownComponent>,
    private translate: TranslateService
  ) {

    this.recentPageService.recentPages$.pipe(takeUntilDestroyed(this.destroyRef))
      .subscribe(recent => {
        this.recentPages = recent;
        this.loading = false;
      });

    this.translate.get(['MENU']).subscribe(translations => {
      this.translations = translations;
    });
  }

  ngOnInit(): void {
  }

  goToPage(page: RecentPage) {
    if (page.url.includes('/board/')) {
      // Create a URL object
      const parsedURL = new URL(page.url, 'http://example.com');
      // Access the cardId parameter
      const cardId = parsedURL.searchParams.get('cardId');

      if (this.router.url.includes('/board/')) {
        const recentBoardId = page.url.split('/')[2];
        const boardId = this.router.url.split('/')[2];

        // Check if boardId is different from recentBoardId
        if (boardId !== recentBoardId) {
          // Disconnect and navigate to the board
          this.disconnectAndNavigateToBoard(page.url);
        }
        else {
          // Navigate to the board
          this.navigateToBoard(page.url);
        }

        this.popoverRef.close();
        return;
      }
      else {
        if (cardId) {
          this.router.navigateByUrl(page.url);
          this.popoverRef.close();
          return;
        }
      }
    }

    this.router.navigateByUrl(page.url);
    this.popoverRef.close();
  }

  // Helper function: Disconnect and navigate to the board
  disconnectAndNavigateToBoard(url: string) {
    this.boardInfoSignalRService.disconnectBoardDataListener();
    this.router.navigateByUrl('/board', { skipLocationChange: true }).then(() =>
      setTimeout(() => {
        this.navigateToBoard(url);
      }, 500)
    );
  }

  // Helper function: Navigate to the board
  navigateToBoard(url: string) {
    setTimeout(() => {
      this.router.navigateByUrl(url);
    }, 500);
  }

  getTranslation(label: string) {
    const [section, key] = label.split('.');

    if (!section || !key || !this.translations[section]) {
      return label;
    }

    const translatedValue = this.translations[section][key];

    return translatedValue !== undefined ? translatedValue : label;
  }

}
