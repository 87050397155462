import * as moment from 'moment';
import { Observable, of } from 'rxjs';
import { switchMap, tap } from 'rxjs/operators';

import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  Account, Activity, ActivityRequest, ActivityService, Contact, ContactFilterRequest, ContactNoteFilterRequest,
  ContactRoleGroup, Custom, CustomerNoteFilterRequest,
  CustomFilterRequest, FilterRequest, FusionChartData, LeadDraft,
  LeadDraftInfo, LeadDraftInfoActivity, LeadDraftInfoRequest,
  LeadDraftRequest, LeadFilterRequest, LeadsGridData,
  LeadsGridDataExportRequest, LeadsGridDataFilterRequest, LeadsReportFieldsCount,
  OpportunityNoteFilterRequest, PagedResponse, Phone, Response, Salutation,
  SearchRequest, SearchResult, ShipmentProfile, ShipmentProfileFilterRequest,
  ShipmentProfileRequest, SpecialDayType, SystemRole, UserGuide, UserGuideInfo,
  UserGuideInfoRequest, UserGuideRequest
} from '@core/api';
import { NoteType } from '@core/api/api.enum';
import { environment } from '@env/environment';
import { TranslateService } from '@ngx-translate/core';
import {
  BaseResponse, LeadDeleteRequest, LeadDraftInfoDeleteRequest, LeadDraftInfoDownload, LeadsReportMapData,
  PhoneSearch, PhoneSearchRequest, LeadsReportMapSearchRequest, SystemRolePermissionSearchRequest,
  ContactDownload,
  ShipmentProfileDownload,
  EmailSearchRequest,
  EmailSearch,
  ListCreatedUser,
  PhoneInsertRequest
} from './api.model';

@Injectable({
  providedIn: 'root'
})
export class ApiService {

  constructor(private http: HttpClient, private translate: TranslateService, private activityService: ActivityService) { }

  contact = {
    get: (uuid: string, checkPermissions: boolean = true): Observable<Response<Contact>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      const params = new HttpParams({
        fromObject: { permissionControl: String(checkPermissions) }
      });
      return this.http.post<Response<Contact>>(environment.apiUrl + '/api/Contact/Get', JSON.stringify(uuid), {
        headers,
        params
      });
    },
    save: (request: any) => {
      return this.http.post<Response<Contact>>(environment.apiUrl + '/api/Contact/Insert', request).pipe(
        switchMap(response => {
          if (response.success) {
            return this.contact.get(response.data.contactId, false);
          }

          return of(response);
        })
      );
    },
    update: (request: any) => {
      return this.http.post<Response<Contact>>(environment.apiUrl + '/api/Contact/Update', request).pipe(
        switchMap(response => {
          if (response.success) {
            return this.contact.get(response.data.contactId, false);
          }

          return of(response);
        })
      );
    },
    search: (request: ContactFilterRequest) => {
      return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Contact/Search', request);
    },
    listSearch: (request: ContactFilterRequest) => {
      return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Contact/ContactListSearch', request);
    },
    exportExcel: (request: ContactFilterRequest) => {
      return this.http.post<Response<ContactDownload[]>>(environment.apiUrl + '/api/Contact/Download', request.filter);
    },
    leadContactExportExcel: (request: ContactFilterRequest) => {
      return this.http.post<Response<ContactDownload[]>>(environment.apiUrl + '/api/Contact/LeadContactDownload', request.filter);
    },
    listSearchLeadContact: (request: ContactFilterRequest) => {
      return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Contact/LeadContactListSearch', request);
    },
    elasticSearch: (request: ContactFilterRequest) => {
      return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Contact/ElasticSearch', request);
    },
    updateStatus: (request: { contactId: string, enabled: boolean }): Observable<Response<Contact>> => {
      return this.http.post<Response<Contact>>(environment.apiUrl + '/api/Contact/UpdateStatus', request);
    },
    delete: (request: any) => {
      return this.http.post<PagedResponse<Contact>>(environment.apiUrl + '/api/Contact/Delete', request);
    },
    listCreatedUsers: (filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> => {
      return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/Contact/ListCreatedUsers', filter);
    },
    listRouteOwners: (filter?: FilterRequest): Observable<Response<ListCreatedUser[]>> => {
      return this.http.post<Response<ListCreatedUser[]>>(environment.apiUrl + '/api/Contact/ListRouteOwners', filter);
    }
  };

  leadDraft = {
    get: (uuid: string): Observable<Response<LeadDraft>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<LeadDraft>>(environment.apiUrl + '/api/LeadDraft/Get', JSON.stringify(uuid), { headers }).pipe(
        tap((response) => {
          response.data = this.leadDraftTransformer(response.data);

          return response;
        })
      );
    },
    save: (request: LeadDraftRequest) => {
      return this.http.post<Response<LeadDraft>>(environment.apiUrl + '/api/LeadDraft/Insert', request);
    },
    update: (request: LeadDraftRequest) => {
      return this.http.post<Response<LeadDraft>>(environment.apiUrl + '/api/LeadDraft/Update', request);
    },
    search: (request: LeadFilterRequest) => {
      return this.http.post<PagedResponse<LeadDraft>>(environment.apiUrl + '/api/LeadDraft/Search', request).pipe(
        tap(response => response.data.results.forEach(leadDraft => this.leadDraftTransformer(leadDraft)))
      );
    },
    delete: (request: LeadDeleteRequest) => {
      return this.http.post<BaseResponse>(environment.apiUrl + '/api/LeadDraft/Delete', request);
    },
    info: {
      get: (uuid: string): Observable<Response<LeadDraftInfo>> => {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<LeadDraftInfo>>(environment.apiUrl + '/api/LeadDraftInfo/Get', JSON.stringify(uuid), { headers }).pipe(
          tap((response) => {
            response.data = this.leadDraftInfoTransformer(response.data);

            return response;
          })
        );
      },
      save: (request: LeadDraftInfoRequest) => {
        return this.http.post<Response<LeadDraftInfo>>(environment.apiUrl + '/api/LeadDraftInfo/Insert', request).pipe(
          switchMap(response => this.leadDraft.info.get(response?.data?.leadDraftInfoId))
        );
      },
      update: (request: LeadDraftInfoRequest) => {
        return this.http.post<Response<LeadDraftInfo>>(environment.apiUrl + '/api/LeadDraftInfo/Update', request).pipe(
          switchMap(response => this.leadDraft.info.get(response?.data?.leadDraftInfoId))
        );
      },
      list: (uuid: string) => {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<LeadDraftInfo[]>>(environment.apiUrl + '/api/LeadDraftInfo/List', JSON.stringify(uuid), { headers }).pipe(
          tap(response => response.data.forEach(info => this.leadDraftInfoTransformer(info)))
        );
      },
      search: (request: LeadFilterRequest) => {
        return this.http.post<PagedResponse<LeadDraftInfo>>(environment.apiUrl + '/api/LeadDraftInfo/Search', request).pipe(
          tap(response => response.data?.results?.forEach(info => this.leadDraftInfoTransformer(info)))
        );
      },
      exportExcel: (request: LeadFilterRequest) => {
        return this.http.post<Response<LeadDraftInfoDownload[]>>(environment.apiUrl + '/api/LeadDraftInfo/Download', request.filter);
      },
      delete: (request: LeadDraftInfoDeleteRequest) => {
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/LeadDraftInfo/Delete', request);
      },
      activityInsert: (request: ActivityRequest, leadDraftInfoId: string): Observable<Response<LeadDraftInfoActivity>> => {
        const params = new HttpParams({
          fromObject: { leadDraftInfoId }
        });

        return this.http.post<Response<LeadDraftInfoActivity>>(environment.apiUrl + '/api/LeadDraftInfoActivity/Insert', request, { params });
      },
      activityUpdate: (request: ActivityRequest, leadDraftInfoId: string): Observable<Response<Activity>> => {
        const params = new HttpParams({
          fromObject: { leadDraftInfoId }
        });

        return this.http.post<Response<Activity>>(environment.apiUrl + '/api/LeadDraftInfoActivity/Update', request, { params })
          .pipe(switchMap(response => {
            if (response.success) {
              return this.activityService.get(response.data.activityId, '/lead');
            }

            return of(response);
          }));
      }
    }
  };

  salutation = {
    list: (request?: FilterRequest): Observable<Response<Salutation[]>> => {
      return this.http.post<Response<Salutation[]>>(environment.apiUrl + '/api/Salutation/List', request);
    }
  };

  customs = {
    list: (request: CustomFilterRequest): Observable<PagedResponse<Custom>> => {
      return this.http.post<PagedResponse<Custom>>(environment.apiUrl + '/api/Customs/Search', request);
    }
  };

  contactRoleGroup = {
    list: (): Observable<Response<ContactRoleGroup[]>> => {
      return this.http.post<Response<ContactRoleGroup[]>>(environment.apiUrl + '/api/ContactRoleGroup/List', {});
    }
  };

  specialDayType = {
    list: (): Observable<Response<SpecialDayType[]>> => {
      return this.http.post<Response<SpecialDayType[]>>(environment.apiUrl + '/api/SpecialDayType/List', {});
    }
  };

  shipmentProfile = {
    get: (uuid: string): Observable<Response<ShipmentProfile>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<ShipmentProfile>>(environment.apiUrl + '/api/ShipmentProfile/Get', JSON.stringify(uuid), { headers });
    },
    save: (request: ShipmentProfileRequest) => {
      return this.http.post<Response<ShipmentProfile>>(environment.apiUrl + '/api/ShipmentProfile/Insert', request);
    },
    search: (request: ShipmentProfileFilterRequest) => {
      return this.http.post<Response<ShipmentProfile[]>>(environment.apiUrl + '/api/ShipmentProfile/Search', request);
    },
    exportExcel: (request: ShipmentProfileFilterRequest) => {
      return this.http.post<Response<ShipmentProfileDownload[]>>(environment.apiUrl + '/api/ShipmentProfile/Download', request);
    }
  };

  search(request: SearchRequest): Observable<Response<SearchResult>> {
    return this.http.post<Response<SearchResult>>(environment.apiUrl + '/api/GlobalSearch/Search', request);
  }

  note<T>(type: NoteType) {
    let url = environment.apiUrl;

    switch (type) {
      case NoteType.ACCOUNT:
      case NoteType.LEAD_DRAFT:
        url += '/api/CustomerNote';
        break;

      case NoteType.CONTACT:
        url += '/api/ContactNote';
        break;

      case NoteType.OPPORTUNITY:
        url += '/api/OpportunityNote';
        break;
    }

    return {
      get: (uuid: string): Observable<Response<T>> => {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });

        return this.http.post<Response<T>>(url + '/Get', JSON.stringify(uuid), { headers }).pipe(
          tap(response => {
            response.data = this.noteTransformer<T>(response.data);

            return response;
          })
        );
      },
      save: (request) => {
        return this.http.post<Response<T>>(url + '/Insert', request);
      },
      update: (request) => {
        return this.http.post<Response<T>>(url + '/Update', request);
      },
      search: (request: ContactNoteFilterRequest | CustomerNoteFilterRequest | OpportunityNoteFilterRequest) => {
        return this.http.post<PagedResponse<T>>(url + '/Search', request).pipe(
          tap(response => response.data.results)
        );
      },
      read: (request) => {
        return this.http.post<Response<T>>(url + '/Read', request);
      },
    };
  }

  leadDraftTransformer(leadDraft: LeadDraft): LeadDraft {
    ['createdOn'].forEach((key) => {
      const date = moment(leadDraft[key]);
      leadDraft[key] = date.isValid() ? date : null;
    });

    return leadDraft;
  }

  leadDraftInfoTransformer(info: LeadDraftInfo): LeadDraftInfo {
    ['createdOn'].forEach((key) => {
      const date = moment(info[key]);
      info[key] = date.isValid() ? date : null;
    });

    return info;
  }

  accountTransformer(account: Account): Account {
    ['createdOn', 'lastDoneVisitDate', 'nextPlannedVisitDate'].forEach((key) => {
      const date = account && account[key] ? moment(account[key]) : moment('');
      account[key] = date?.isValid() ? date : null;
    });

    return account;
  }

  noteTransformer<T>(note: T): T {
    ['createdOn', 'modifiedOn'].forEach((key) => {
      const date = moment(note[key]);
      note[key] = date.isValid() ? date : null;
    });

    return note;
  }

  // tslint:disable-next-line: member-ordering
  userGuide = {
    list: (request?: FilterRequest): Observable<PagedResponse<UserGuide>> => {
      return this.http.post<PagedResponse<UserGuide>>(environment.apiUrl + '/api/UserGuide/List', request);
    },
    save: (request: UserGuideRequest, filters: FilterRequest) => {
      return this.http.post<Response<UserGuide>>(environment.apiUrl + '/api/UserGuide/Insert', request).pipe(
        switchMap(response => {
          if (response.success) {
            return this.userGuide.list(filters);
          }
        })
      );
    },
    update: (request: UserGuideRequest, filters: FilterRequest) => {
      return this.http.post<Response<UserGuide>>(environment.apiUrl + '/api/UserGuide/Update', request).pipe(
        switchMap(response => {
          if (response.success) {
            return this.userGuide.list(filters);
          }
        })
      );
    },
    delete: (request: UserGuideRequest) => {
      return this.http.post<Response<UserGuide>>(environment.apiUrl + '/api/UserGuide/Delete', request);
    },
    get: (userGuideId: string): Observable<Response<UserGuide>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<UserGuide>>(environment.apiUrl + '/api/UserGuide/Get', JSON.stringify(userGuideId), { headers });
    },
  };

  // tslint:disable-next-line: member-ordering
  userGuideInfo = {
    get: (userGuideInfoId: string): Observable<Response<UserGuideInfo>> => {
      const headers = new HttpHeaders({ 'content-type': 'application/json' });
      return this.http.post<Response<UserGuideInfo>>(environment.apiUrl + '/api/UserGuideInfo/Get', JSON.stringify(userGuideInfoId), { headers });
    },
    search: (request?: FilterRequest): Observable<PagedResponse<UserGuideInfo>> => {
      return this.http.post<PagedResponse<UserGuideInfo>>(environment.apiUrl + '/api/UserGuideInfo/Search', request);
    },
    save: (request: UserGuideInfoRequest) => {
      return this.http.post<Response<UserGuideInfo>>(environment.apiUrl + '/api/UserGuideInfo/Insert', request);
    },
    update: (request: UserGuideInfoRequest) => {
      return this.http.post<Response<UserGuideInfo>>(environment.apiUrl + '/api/UserGuideInfo/Update', request);
    },
    delete: (request: UserGuideInfoRequest) => {
      return this.http.post<Response<UserGuide>>(environment.apiUrl + '/api/UserGuideInfo/Delete', request);
    },
  };

  // tslint:disable-next-line: member-ordering
  systemPermission = {
    list: (request?: FilterRequest): Observable<PagedResponse<any>> => {
      return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/SystemPermission/List', request);
    },
    search: (request?: FilterRequest): Observable<PagedResponse<any>> => {
      return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/SystemPermission/Search', request);
    }
  };

  // tslint:disable-next-line: member-ordering
  systemRole = {
    list: (request?: FilterRequest): Observable<PagedResponse<SystemRole>> => {
      return this.http.post<PagedResponse<SystemRole>>(environment.apiUrl + '/api/SystemRole/List', request);
    },
    update: (request: SystemRole) => {
      return this.http.post<Response<SystemRole>>(environment.apiUrl + '/api/SystemRole/Update', request);
    },
    insert: (request: SystemRole) => {
      return this.http.post<Response<SystemRole>>(environment.apiUrl + '/api/SystemRole/Insert', request);
    },
  };

  // tslint:disable-next-line: member-ordering
  systemUserRole = {
    search: (request?: FilterRequest): Observable<PagedResponse<any>> => {
      return this.http.post<PagedResponse<any>>(environment.apiUrl + '/api/SystemUserRole/Search', request);
    }
  };

  // tslint:disable-next-line: member-ordering
  systemRolePermission = {
    search: (request?: SystemRolePermissionSearchRequest) => {
      return this.http.post<Response<any>>(environment.apiUrl + '/api/SystemRolePermission/Search', request);
    },
  };

  // tslint:disable-next-line: member-ordering
  leadsReportFieldsCount = {
    get: () => this.http.post<Response<LeadsReportFieldsCount>>(environment.apiUrl + '/api/Report/LeadsReportFieldsCount', {})
  };

  // tslint:disable-next-line: member-ordering
  leadsReportStatusChart = {
    get: () => this.http.post<Response<FusionChartData[]>>(environment.apiUrl + '/api/Report/LeadsReportStatusChart', {})
  };

  // tslint:disable-next-line: member-ordering
  leadsReportTableData = {
    search: (request: LeadsGridDataFilterRequest) => {
      return this.http.post<PagedResponse<LeadsGridData>>(environment.apiUrl + '/api/Report/LeadsReportTable', request).pipe(
        tap(response => response.data.results.forEach(leads => this.leadsReportTableDataTransformer(leads)))
      );
    },
    exportData: (request: LeadsGridDataExportRequest) => {
      return this.http.post<Response<LeadsGridData[]>>(environment.apiUrl + '/api/Report/LeadsExport', request).pipe(
        tap(response => response.data.forEach(leads => this.leadsReportTableDataTransformer(leads)))
      );
    }
  };

  // tslint:disable-next-line: member-ordering
  leadsReportMapData = {
    search: (request: LeadsReportMapSearchRequest) => {
      return this.http.post<Response<LeadsReportMapData[]>>(environment.apiUrl + '/api/Report/LeadsReportMapData', request);
    }
  };

  // tslint:disable-next-line: member-ordering
  phone = {
    search: (request: PhoneSearchRequest) => {
      return this.http.post<PagedResponse<PhoneSearch>>(environment.apiUrl + '/api/Phone/Search', request);
    },
    insert: (request: PhoneInsertRequest) => {
      return this.http.post<Response<Phone>>(environment.apiUrl + '/api/Phone/Insert', request);
    }
  };

  // tslint:disable-next-line: member-ordering
  email = {
    search: (request: EmailSearchRequest) => {
      return this.http.post<PagedResponse<EmailSearch>>(environment.apiUrl + '/api/Email/Search', request);
    }
  };

  leadsReportTableDataTransformer(leads: LeadsGridData): LeadsGridData {
    ['diff'].forEach((key) => {
      if (parseInt(leads[key], 0) < 60) {
        leads.createDateDiff = this.translate.instant('GENERAL.VALUE_MINUTE_AGO', { value: leads[key] });
      } else {
        if (parseInt(leads[key], 0) < 1440) {
          leads.createDateDiff = this.translate.instant('GENERAL.VALUE_HOURS_AGO', { value: (parseInt(leads[key], 0) / 60).toFixed(0) });
        } else {
          leads.createDateDiff = this.translate.instant('GENERAL.VALUE_DAYS_AGO', { value: (parseInt(leads[key], 0) / 1440).toFixed(0) });
        }
      }
    });
    return leads;
  }
}
