import { HttpClient, HttpHeaders } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { Response } from '../api.model';
import { OpportunityOfferMailActionStatus, OpportunityOfferMailActionStatusUpdateRequest } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class OpportunityOfferMailActionService {
    private readonly http: HttpClient = inject(HttpClient);

    getOpportunityOfferMailActionStatus(salesOrganizationId: string): Observable<Response<OpportunityOfferMailActionStatus[]>> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<OpportunityOfferMailActionStatus[]>>
            (`${environment.apiUrl}/api/OpportunityOfferMailActionStatus/Get`, JSON.stringify(salesOrganizationId), { headers });

    }

    updateOpportunityOfferMailActionStatus(opportunityOfferMailActionStatus: OpportunityOfferMailActionStatusUpdateRequest): Observable<Response<boolean>> {
        return this.http.post<Response<boolean>>(`${environment.apiUrl}/api/OpportunityOfferMailActionStatus/Update`, opportunityOfferMailActionStatus);
    }

}
