@defer (when loading === false) {

<div class="flex flex-row p-4 justify-between items-center">

    <div class="flex flex-col">
        <h2 class="font-normal">{{ opportunityOfferMail.organizationName ?? '--' }}</h2>
        <h2 class="font-normal">{{ opportunityOfferMail.customerName ?? '--' }}</h2>
    </div>

    <div class="flex gap-4">

        @if(opportunityOfferMail.actions.length >= 0) {
        <ng-container [ngTemplateOutlet]="afterResponse"></ng-container>
        }

        @if (opportunityOfferMail.respondedActionTypeId) {
        <ng-container [ngTemplateOutlet]="beforeResponse"></ng-container>
        }

    </div>

</div>

<mat-divider></mat-divider>

@if(opportunityOfferMail.isExpired) {

<div class="flex flex-row p-4 justify-center items-center pdf-viewer-height">
    <div class="flex flex-col justify-center items-center size-[400px]">
        <net-iconify-icon [icon]="mdiWarningCircleOutline" color="red" size="100"></net-iconify-icon>
        <h2 class="font-normal text-center">{{'OPPORTUNITY_OFFER.EXPIRED_MESSAGE' | translate }}</h2>
    </div>
</div>
} @else {

@if(opportunityOfferMail.respondedActionTypeId) {
<p class="px-4 py-2">
    @switch(opportunityOfferMail.respondedActionTypeId) {

    @case (opportunityOfferMailActionType.REJECTED) {
    {{'OPPORTUNITY_OFFER.REJECTION_DATE' |translate}}
    }

    @case (opportunityOfferMailActionType.REQUESTED_REVISION) {
    {{'OPPORTUNITY_OFFER.REVISION_REQUEST_DATE' | translate}}
    }

    @case (opportunityOfferMailActionType.ACCEPTED) {
    {{'OPPORTUNITY_OFFER.APPROVAL_DATE' | translate}}
    }

    }: {{ opportunityOfferMail.respondedDate | momentFormat: 'DD MMMM YYYY HH:mm'}}
</p>
}

<pdf-viewer [src]="pdfUrl" [original-size]="true" [fit-to-page]="false" (after-load-complete)="pageRendered($event)"
    [ngClass]="{
        'invisible h-0': !pdfRendered,
        'pdf-viewer-height visible': pdfRendered
        }"></pdf-viewer>


<div [ngClass]="{
    'invisible': pdfRendered,
    'visible flex justify-center items-center mt-6': !pdfRendered
    }">
    <div class="grid w-[50%]">
        <ngx-skeleton-loader appearance="line" count="10"></ngx-skeleton-loader>
        <ngx-skeleton-loader appearance="line" count="10"></ngx-skeleton-loader>
    </div>
</div>

}

} @placeholder (minimum 500ms) {

<div class="grid grid-cols-2 gap-x-4 p-5 ">
    <ngx-skeleton-loader appearance="line" count="2" fxFlex></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" count="2" fxFlex></ngx-skeleton-loader>

</div>

<mat-divider></mat-divider>

<div class="p-4 h-full">
    <ngx-skeleton-loader appearance="line" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" count="1"></ngx-skeleton-loader>
    <ngx-skeleton-loader appearance="line" count="1"></ngx-skeleton-loader>
</div>

}

<ng-template #afterResponse>
    @for(action of opportunityOfferMail.actions; track action.opportunityOfferMailActionTypeId) {

    @switch(action.opportunityOfferMailActionTypeId) {

    @case (opportunityOfferMailActionType.REJECTED) {
    <net-progress-button class="ml-auto" customClass="!bg-gray-500" [icon]="icClose" (btnClick)="reject()"
        text="{{'OPPORTUNITY_OFFER.REJECT' | translate }}"></net-progress-button>
    }

    @case (opportunityOfferMailActionType.REQUESTED_REVISION) {
    <net-progress-button class="ml-auto" text="{{'OPPORTUNITY_OFFER.REQUEST_REVISION' | translate }}"
        [icon]="msRotateLeft" (btnClick)="requestRevision()"></net-progress-button>
    }

    @case (opportunityOfferMailActionType.ACCEPTED) {
    <net-progress-button class="ml-auto" customClass="!bg-green-500" [icon]="icCheck" (btnClick)="accept()"
        text="{{'OPPORTUNITY_OFFER.ACCEPT' | translate }}"></net-progress-button>
    }

    }
    }
</ng-template>

<ng-template #beforeResponse>
    @switch(opportunityOfferMail.respondedActionTypeId) {

    @case (opportunityOfferMailActionType.REJECTED) {
    <button [disabled]="true" class="!bg-red-700 opacity-70" mat-raised-button>
        <net-iconify-icon [icon]="icClose" color="white"></net-iconify-icon>
        <span class="text-white">{{'OPPORTUNITY_OFFER.REJECTED' | translate }}</span>
    </button>
    }

    @case (opportunityOfferMailActionType.REQUESTED_REVISION) {
    <button [disabled]="true" class="opacity-70 !bg-primary-500" mat-raised-button>
        <net-iconify-icon [icon]="msRotateLeft" color="white"></net-iconify-icon>
        <span class="text-white">{{'OPPORTUNITY_OFFER.REQUESTED_REVISION' | translate }}</span>
    </button>
    }

    @case (opportunityOfferMailActionType.ACCEPTED) {
    <button [disabled]="true" class="!bg-green-500 opacity-70" mat-raised-button>
        <net-iconify-icon [icon]="icCheck" color="white"></net-iconify-icon>
        <span class="text-white">{{'OPPORTUNITY_OFFER.ACCEPTED' | translate }}</span>
    </button>

    }

    }
</ng-template>