export function createFileUrl(url: string, headers: { [key: string]: string } = {}): Promise<string> {

    return new Promise<string>((resolve, reject) => {
        fetch(url, { method: 'GET', headers })
            .then((response) => response.blob())
            .then(blob => {
                const fileUrl = URL.createObjectURL(blob);
                resolve(fileUrl);
            })
            .catch(() => reject('File fetch failed.'));
    });
}
