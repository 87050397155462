
import { of } from 'rxjs';
import { catchError, mergeMap, switchMap, tap } from 'rxjs/operators';
import moment from 'moment';

import { inject, Injectable } from '@angular/core';
import { AuthService } from '@core/auth/auth.service';
import { AuthActionTypes, Login, LoginError, LoginSuccess } from '@core/store';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { RecentPageService } from '@core/api';

@Injectable()
export class AuthEffects {
    private readonly recentPageService = inject(RecentPageService);
    constructor(
        private auth: AuthService,
        private actions$: Actions,
    ) { }

    login$ = createEffect(() => this.actions$.pipe(
        ofType(AuthActionTypes.LOGIN),
        mergeMap((request: Login) => this.auth.login(request.payload)),
        tap(response => {
            this.auth.persistToken(response);
        }),
        switchMap(response => {
            if (response.success) {
                const locale = response.data.user.culture;
                this.recentPageService.setRecentPages(JSON.parse(response.data.user.recentPage) ?? []);
                this.recentPageService.setUser(response.data.user);
                moment.locale(locale);

                return of(new LoginSuccess(response));
            } else {
                return of(new LoginError(response as any));
            }
        }),
        catchError((error) => of(new LoginError(error)))
    ));
}

