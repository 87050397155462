import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable } from 'rxjs';
import { BaseResponse, PagedResponse, Response } from '../api.model';
import {
    CustomerExperienceQuestionAnswerSearchRequest,
    CustomerExperienceQuestionAnswerRequest,
    CustomerExperienceQuestionAnswerDeleteRequest,
    CustomerExperienceQuestionAnswerDownload
} from '@core/api';
import { CustomerExperienceQuestionAnswer } from './customer-experience-question-answer.model';

@Injectable({
    providedIn: 'root'
})
export class CustomerExperienceQuestionAnswerService {
    constructor(private http: HttpClient) { }

    get(request: string) {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<Response<CustomerExperienceQuestionAnswer>>(environment.apiUrl + '/api/CxQA/Get',
            JSON.stringify(request), { headers });
    }
    search(request: CustomerExperienceQuestionAnswerSearchRequest): Observable<PagedResponse<CustomerExperienceQuestionAnswer>> {
        return this.http.post<PagedResponse<CustomerExperienceQuestionAnswer>>(environment.apiUrl + '/api/CxQA/Search', request);
    }

    insert(request: CustomerExperienceQuestionAnswerRequest): Observable<Response<CustomerExperienceQuestionAnswer>> {
        return this.http.post<Response<CustomerExperienceQuestionAnswer>>(environment.apiUrl + '/api/CxQA/Insert', request);
    }

    bulkInsert(request: any): Observable<Response<CustomerExperienceQuestionAnswer>> {
        return this.http.post<Response<any>>(environment.apiUrl + '/api/CxQA/BulkInsert', request);
    }

    update(request: CustomerExperienceQuestionAnswerRequest): Observable<Response<CustomerExperienceQuestionAnswer>> {
        return this.http.post<Response<CustomerExperienceQuestionAnswer>>(environment.apiUrl + '/api/CxQA/Update', request);
    }

    delete(request: CustomerExperienceQuestionAnswerDeleteRequest): Observable<BaseResponse> {
        const headers = new HttpHeaders({ 'content-type': 'application/json' });
        return this.http.post<BaseResponse>(environment.apiUrl + '/api/CxQA/Delete', JSON.stringify(request.cxQAId), { headers });
    }

    download(request: any): Observable<Response<CustomerExperienceQuestionAnswerDownload[]>> {
        return this.http.post<Response<CustomerExperienceQuestionAnswerDownload[]>>(environment.apiUrl + '/api/CxQA/Download', request);
    }
}
