<div class="flex flex-row justify-between items-center" mat-dialog-title>
    <div class="flex flex-col">
        <h2 class="m-0">

            @switch(type) {

            @case (opportunityOfferMailActionType.REJECTED) {
            <span>{{'OPPORTUNITY_OFFER.REJECT' | translate}}</span>
            }

            @case (opportunityOfferMailActionType.REQUESTED_REVISION) {
            <span>{{'OPPORTUNITY_OFFER.REQUEST_REVISION' | translate}}</span>
            }

            }
        </h2>
    </div>

    <button type="button" class="text-secondary" mat-dialog-close mat-icon-button>
        <net-iconify-icon [icon]="icClose"></net-iconify-icon>
    </button>
</div>
<mat-divider class="-mx-6 text-border"></mat-divider>
<mat-dialog-content>

    @switch(type) {

    @case (opportunityOfferMailActionType.REJECTED) {
    <P>{{'OPPORTUNITY_OFFER.REJECT_SUB_MESSAGE' |translate}}</P>
    }

    @case (opportunityOfferMailActionType.REQUESTED_REVISION) {
    <span>{{'OPPORTUNITY_OFFER.REQUESTED_REVISION_REJECT_SUB_MESSAGE' | translate}}</span>
    }

    }

    <mat-form-field class="w-full mt-2">
        <mat-label>{{ "GENERAL.DESCRIPTION" | translate }}</mat-label>
        <textarea cdkAutosizeMaxRows="6" cdkTextareaAutosize cdkAutosizeMinRows="4" [maxlength]="1000"
            [formControl]="description" matInput></textarea>
    </mat-form-field>

</mat-dialog-content>

<mat-dialog-actions class="flex flex-row justify-between items-center">

    <net-progress-button class="ml-auto" [icon]="icSave" [active]="loading" (btnClick)="onSubmit()"
        [text]="'GENERAL.SAVE' | translate"></net-progress-button>

</mat-dialog-actions>