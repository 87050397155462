import { Subject } from 'rxjs';
import { debounceTime, distinctUntilChanged, filter, skip, tap } from 'rxjs/operators';

import { FocusMonitor } from '@angular/cdk/a11y';
import { coerceBooleanProperty } from '@angular/cdk/coercion';
import {
  Component, DoCheck, ElementRef, HostBinding, Input, OnInit, Optional, Self, ViewChild
} from '@angular/core';
import { UntypedFormControl, NgControl } from '@angular/forms';
import {
  MatAutocomplete, MatAutocompleteSelectedEvent, MatAutocompleteTrigger
} from '@angular/material/autocomplete';
import { MatFormFieldControl } from '@angular/material/form-field';
import { CurrencyService, TransactionCurrency, TransactionCurrencySearchRequest } from '@core/api';
import { TranslateService } from '@ngx-translate/core';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';
import { FormChangedDirective } from '@shared/directives';

@Component({
  selector: 'net-select-currency',
  templateUrl: './select-currency.component.html',
  styleUrls: ['./select-currency.component.scss'],
  providers: [
    {
      provide: MatFormFieldControl,
      useExisting: SelectCurrencyComponent
    }
  ],
  standalone: true,
  imports: [
    SharedModule,
    FormChangedDirective
  ]
})
export class SelectCurrencyComponent implements OnInit, DoCheck {
  static nextId = 0;

  loading = false;
  focused = false;
  errorState = false;
  controlType = 'select-currency';
  describedBy = '';
  tooltip: string = null;

  currencies: TransactionCurrency[];
  filteredCurrencies: TransactionCurrency[] = [];
  selectedCurrency: TransactionCurrency;

  inputCtrl = new UntypedFormControl();
  stateChanges = new Subject<void>();

  icArrowDropDown = Icon.IC_TWOTONE_ARROW_DROP_DOWN;

  @Input() tabIndex: number;
  @Input() removeCurrentCurrency: boolean;
  @Input() onlyEnabledCurrencies = false;

  @Input()
  get placeholder(): string { return this._placeholder; }
  set placeholder(value: string) {
    this._placeholder = value;
    this.stateChanges.next();
  }
  private _placeholder: string;

  @Input()
  get required(): boolean { return this._required; }
  set required(value: boolean) {
    this._required = coerceBooleanProperty(value);
    this.stateChanges.next();
  }
  private _required = false;

  @Input()
  get disabled(): boolean { return this._disabled; }
  set disabled(value: boolean) {
    if (value) {
      this.inputCtrl.disable();
    } else {
      this.inputCtrl.enable();
    }

    this._disabled = value;
    this.stateChanges.next();
  }
  private _disabled = false;

  @Input()
  get value(): TransactionCurrency | null { return this.selectedCurrency; }
  set value(value: TransactionCurrency | null) {
    if (!value && !this.removeCurrentCurrency) {
      this.currencyService.getDefaultCurrency().subscribe((defaultCurrency) => {
        this.updateValue(defaultCurrency.data);
      });
    } else { this.updateValue(value); }
  }

  @ViewChild('auto') auto: MatAutocomplete;
  @ViewChild('input') input: ElementRef<HTMLInputElement>;
  @ViewChild(MatAutocompleteTrigger, { read: MatAutocompleteTrigger }) inputAutoComplit: MatAutocompleteTrigger;
  @ViewChild(FormChangedDirective) formChangedDirective!: FormChangedDirective;

  @HostBinding('id') id = `select-currency-${SelectCurrencyComponent.nextId++}`;
  @HostBinding('attr.aria-describedby') describedByBinding = this.describedBy;

  get shouldLabelFloat() { return this.focused || !this.empty; }

  get empty() { return !this.value; }

  constructor(
    private currencyService: CurrencyService,
    private _focusMonitor: FocusMonitor,
    private _elementRef: ElementRef<HTMLElement>,
    private translate: TranslateService,
    @Optional() @Self() public ngControl: NgControl
  ) {
    // Material form field implementation
    _focusMonitor.monitor(_elementRef, true).subscribe(origin => {
      if (this.disabled) {
        return;
      }

      if (this.focused && !origin) {
        this.onTouched();
      }
      this.focused = !!origin;
      this.stateChanges.next();
    });

    if (this.ngControl != null) {
      this.ngControl.valueAccessor = this;
    }

    // Listen input control value changes and filter results
    this.inputCtrl.valueChanges.pipe(
      skip(1),
      debounceTime(200),
      distinctUntilChanged(),
      filter(value => typeof value === 'string'),
      tap(() => {
        this.filteredCurrencies = [];
        this.selectedCurrency = null;
      }),
    ).subscribe((response) => {
      const filterValue = response.toLowerCase();
      this.filteredCurrencies = this.currencies
        .filter(currency => this.translate.instant(`TransactionCurrency.${currency.currencyName}`)?.toLowerCase().indexOf(filterValue) === 0)
        .sort((a, b) => (a.orderBy > b.orderBy ? 1 : -1));

      this.tooltip = this.filteredCurrencies.length > 0 ? null : this.translate.instant('GENERAL.THERE_IS_NO_CURRENCY_TO_CHOOSE_FROM');
    });
  }

  ngOnInit() {
    this.fetchList();
  }

  fetchList() {
    this.currencyService.search({
      page: 1,
      pageSize: 250,
      filter: {
        ...this.removeCurrentCurrency && !this.onlyEnabledCurrencies ? { definedExchangeRateFlag: false } : {},
        ...this.removeCurrentCurrency ? { defaultTransactionCurrencyFlag: false } : {},
        ...this.onlyEnabledCurrencies ? { enabled: true } : {}
      }
    } as TransactionCurrencySearchRequest).subscribe((response) => {
      this.loading = false;
      this.currencies = response.data.results.sort((a, b) => (a.orderBy > b.orderBy ? 1 : -1));
      this.filteredCurrencies = this.currencies;

      this.tooltip = this.filteredCurrencies.length > 0 ? null : this.translate.instant('GENERAL.THERE_IS_NO_CURRENCY_TO_CHOOSE_FROM');

      if (this.value) {
        this.inputCtrl.setValue(this.value);
        this.formChangedDirective.setOldValue();
      }
    });
  }


  reset() {
    if (this.inputCtrl.pristine) {
      return;
    }

    this.value = null;
    this.inputCtrl.setValue(null);
    this.inputCtrl.markAsPristine();
    this.filteredCurrencies = this.currencies;
  }

  onBlur() {
    // On blur with nothing selected reset component
    if (this.ngControl.dirty && !this.auto.isOpen && !this.value) {
      this.reset();
    }
  }

  onClosed() {
    // On panel close with nothing selected reset component
    if (this.ngControl.dirty && !this.value) {
      this.reset();
    }
  }

  openPanel(event: PointerEvent | any): void {
    event.stopPropagation();
    this.inputAutoComplit.openPanel();
  }

  onSelectionChange(change: MatAutocompleteSelectedEvent) {
    this.value = this.currencies.find(curreny => curreny.transactionCurrencyId === change.option.value);
    this.input.nativeElement?.blur();
  }

  updateValue(value: TransactionCurrency | null) {
    this.selectedCurrency = value;
    this.inputCtrl.setValue(value ?? '');
    this.onChange(value);
    this.stateChanges.next();
  }

  // tslint:disable-next-line:max-line-length
  displayName = (currency: TransactionCurrency) => currency ? this.translate.instant(`TransactionCurrency.${currency.currencyName}`) + ' - ' + currency.isoCurrencyCode : '';
  onChange = (_: any) => { };
  onTouched = () => { };
  compareWith = (one: string, two: string) => one && two && one === two;
  onContainerClick = () => { };
  registerOnChange = (fn: any) => this.onChange = fn;
  registerOnTouched = (fn: any) => this.onTouched = fn;
  setDisabledState = (isDisabled: boolean) => this.disabled = isDisabled;
  setDescribedByIds = (ids: string[]) => this.describedBy = ids.join(' ');
  writeValue = (value: TransactionCurrency) => this.value = value;
  ngOnDestroy = () => this.stateChanges.complete();

  ngDoCheck() {
    // Reflect control valid status for mat form field error state
    if (this.ngControl) {
      this.errorState = this.ngControl.invalid && this.ngControl.touched;
      this.stateChanges.next();
    }
  }
}
