import { inject, Injectable } from '@angular/core';
import { SystemUserService, User } from '@core/api';
import { RecentPage } from './recent-pages.model';
import { BehaviorSubject, of, switchMap } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class RecentPageService {

  private recentPagesSubject: BehaviorSubject<RecentPage[]> = new BehaviorSubject<RecentPage[]>([]);
  public recentPages$ = this.recentPagesSubject.asObservable().pipe(
    switchMap(() => of(this.getRecentPagesFromLocalStorage()))
  );

  private user: User = null;

  private readonly systemUserService = inject(SystemUserService);

  constructor() { }

  setRecentPages(recentPages: RecentPage[]) {
    localStorage.setItem('recentPages', JSON.stringify(recentPages));
    this.recentPagesSubject.next(recentPages);
  }

  setUser = (user: User) => this.user = user;

  getRecentPages = () => this.getRecentPagesFromLocalStorage();

  addRecentPage(addedPage: RecentPage) {
    let recentPages = this.getRecentPagesFromLocalStorage();

    const index = recentPages.findIndex(recent => recent.url === addedPage.url);

    // recent pages kısmında card ismi yerine board ismi göründüğü için ikinci koşul eklendi
    if (index === 0 && !recentPages[index].url.includes('/board/')) {
      return;
    }

    if (index !== -1) {
      recentPages = recentPages.filter(recentPage => recentPage.url !== addedPage.url);
    }

    recentPages = [addedPage, ...recentPages].splice(0, 10);

    this.saveRecentPages(recentPages);
  }

  private saveRecentPages = (recentPages: RecentPage[]) => {

    this.systemUserService.updateRecentPageConfigs(
      this.user?.userId,
      JSON.stringify(recentPages)
    ).subscribe(() => {
      this.setRecentPages(recentPages);
    });

  }

  private getRecentPagesFromLocalStorage = () => {
    return JSON.parse(localStorage.getItem('recentPages')) ?? [];
  }
}
