import { Component, Inject } from '@angular/core';
import { FormControl, Validators } from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { OpportunityOfferMailActionType, OpportunityOfferMailDialogCloseModel } from '@core/api';
import { Icon } from '@shared/enums';
import { SharedModule } from '@shared/modules';

@Component({
  selector: 'net-opportunity-offer-mail-dialog',
  standalone: true,
  imports: [
    SharedModule
  ],
  templateUrl: './opportunity-offer-mail-dialog.component.html',
  styleUrl: './opportunity-offer-mail-dialog.component.scss'
})
export class OpportunityOfferMailDialogComponent {

  icClose = Icon.IC_CLOSE;
  icSave = Icon.IC_SAVE;

  loading = false;
  opportunityOfferMailActionType = OpportunityOfferMailActionType;
  type: OpportunityOfferMailActionType;

  description: FormControl = new FormControl<string>('', {
    validators: OpportunityOfferMailActionType.REQUESTED_REVISION === this.data.type ? [Validators.required] : null
  });

  constructor(
    @Inject(MAT_DIALOG_DATA) public data: OpportunityOfferMailDialogCloseModel,
    private dialogRef: MatDialogRef<OpportunityOfferMailDialogComponent>
  ) {
    this.type = data.type;
  }

  onSubmit() {
    this.description.markAsTouched();
    if (this.description.valid) {
      this.dialogRef.close({ type: this.type, description: this.description.value });
    }
  }
}
