import { authGuard, GuestGuard, Permission, userPermissionGuard } from '@core/auth';
import { LayoutComponent } from './layouts/layout/layout.component';
import { VexRoutes } from '@vex/interfaces/vex-route.interface';
import { GuestLayoutComponent } from './layouts/components/guest-layout/guest-layout.component';
import { AppBreadcrumbResolverService } from './app-resolver.service';
import { OpportunityOfferMailComponent } from './opportunity-offer-mail/opportunity-offer-mail.component';
import { SurveyPreviewComponent } from './management/survey-management/survey-details/components/survey-preview/survey-preview.component';

export const appRoutes: VexRoutes = [
  {
    path: 'auth',
    component: GuestLayoutComponent,
    canActivate: [GuestGuard],
    loadChildren: () => import('./guest/guest.module').then(m => m.GuestModule)
  },
    {
    path: 'survey-xooi',
    component: SurveyPreviewComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'unsubscribe-email',
    component: SurveyPreviewComponent,
    canActivate: [GuestGuard],
    data: {
      isUnsubscribe: true,
      isPreview: false
    }
  },
  {
    path: 'opportunity-offer-mail/:opportunityOfferMailSentId',
    component: OpportunityOfferMailComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'survey-xooi',
    component: SurveyPreviewComponent,
    canActivate: [GuestGuard],
  },
  {
    path: 'unsubscribe-email',
    component: SurveyPreviewComponent,
    canActivate: [GuestGuard],
    data: {
      isUnsubscribe: true,
      isPreview: false
    }
  },
  {
    path: '',
    component: LayoutComponent,
    canActivate: [authGuard],
    children: [
      {
        path: 'search',
        loadChildren: () => import('./search/search.module').then(m => m.SearchModule)
      },
      {
        path: '',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.HOME_DASHBOARD,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./dashboard/dashboard.module').then(m => m.DashboardModule)
      },
      {
        path: 'leads-drafts',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.LEAD_DRAFT_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./lead-draft/lead-draft.module').then(m => m.LeadDraftModule)
      },
      {
        path: 'leads',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACCOUNT_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./lead/lead.module').then(m => m.LeadModule)
      },
      {
        path: 'activities',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACTIVITY_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./activity/activity.module').then(m => m.ActivityModule)
      },
      {
        path: 'opportunities',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.OPPORTUNITY_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./opportunity/opportunity.module').then(m => m.OpportunityModule)
      }, {
        path: 'offers',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.OFFER_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./offer/offer.module').then(m => m.OfferModule)
      },
      {
        path: 'assignment',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ASSIGNMENT_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./assignment/assignment.module').then(m => m.AssignmentModule)
      },
      {
        path: 'mail',
        data: {
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./mail/mail.module').then(m => m.MailModule)
      }, {
        path: 'board',
        canActivate: [userPermissionGuard],
        data: {
          permission: [Permission.BOARD_ADMIN, Permission.BOARD_SEARCH],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./board/board.module').then(m => m.BoardModule)
      },
      {
        path: 'calendar',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.TEMP_CALENDAR,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./calendar/calendar.module').then(m => m.CalendarModule)
      },
      {
        path: 'contacts',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.CONTACT_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./contact/contact.module').then(m => m.ContactModule)
      },
      {
        path: 'notification',
        canActivate: [userPermissionGuard],
        data: {
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./notification/notification.module').then(m => m.NotificationModule)
      }, {
        path: 'nps-report',
        canActivate: [userPermissionGuard],
        loadChildren: () => import('./nps-report/nps-report.module').then(m => m.NPSReportModule),
        data: {
          permission: [Permission.MENU_NPS_REPORT],
          breadcrumbs: AppBreadcrumbResolverService
        }
      }, {
        path: 'custom-list',
        canActivate: [userPermissionGuard],
        data: {
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./custom-list/custom-list.module').then(m => m.CustomListModule)
      },
      {
        path: 'reports',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.REPORT_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./report/report.module').then(m => m.ReportModule)
      },
      {
        path: 'customer-experience',
        canActivate: [userPermissionGuard],
        data: {
          permission: [Permission.CUSTOMER_EXPERIENCE_TICKET_SEARCH],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./customer-experience/customer-experience.module').then(m => m.CustomerExperienceModule)
      },
      {
        path: 'note',
        loadChildren: () => import('./note/note.module').then(m => m.NoteModule),
        data: {
          permission: Permission.CUSTOMER_NOTE_ENTRY,
          breadcrumbs: AppBreadcrumbResolverService
        }
      },
      {
        path: 'user-guide',
        data: {
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./user-guide/user-guide.module').then(m => m.UserGuideModule)
      },
      {
        path: 'video-conference',
        loadChildren: () => import('./video-conference/video-conference.module').then(m => m.VideoConferenceModule),
        data: {
          permission: Permission.SALES_CALL_MONITOR,
          breadcrumbs: AppBreadcrumbResolverService
        }
      },
      {
        path: 'profile',
        loadChildren: () => import('./profile/profile.module').then(m => m.ProfileModule),
        data: {
          permission: Permission.CUSTOMER_NOTE_ENTRY,
          breadcrumbs: AppBreadcrumbResolverService
        }
      },
      {
        path: 'management/currency-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.TRANSACTION_CURRENCY_MENU,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/currency-management/currency-management.module').then(m => m.CurrencyManagementModule)
      },
      {
        path: 'management/role-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACCESS_ALL,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/role-management/role-management.module').then(m => m.RoleManagementModule)
      },
      {
        path: 'management/customer-experience-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: [
            Permission.CUSTOMER_EXPERIENCE_CHANNEL_SETTINGS_MENU,
            Permission.CUSTOMER_EXPERIENCE_CATEGORIES_AND_STATUSES_MENU,
            Permission.CUSTOMER_EXPERIENCE_AUTOMATIONS_MENU,
            Permission.CUSTOMER_EXPERIENCE_SLA_MENU,
            Permission.CUSTOMER_EXPERIENCE_QUICK_REPLIES_MENU,
            Permission.CUSTOMER_EXPERIENCE_WORKING_HOURS_MENU,
            Permission.CUSTOMER_EXPERIENCE_CHARTS_MENU,
            Permission.CUSTOMER_EXPERIENCE_UNWANTED_PHRASES_MENU,
            Permission.CUSTOMER_EXPERIENCE_QUESTION_ANSWER_MENU
          ],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/customer-experience-management/customer-experience-management.module')
          .then(m => m.CustomerExperienceManagementModule)
      },
      {
        path: 'management/survey-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.MENU_SURVEY_MANAGEMENT,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/survey-management/survey-management.module').then(m => m.SurveyManagementModule)
      },
      {
        path: 'management/tax-office',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACCESS_ALL,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/tax-office-management/tax-office-management.module').then(m => m.TaxOfficeManagementModule)
      },
      {
        path: 'management/sales-organization',
        canActivate: [userPermissionGuard],
        data: {
          permission: [Permission.SHOW_OPPORTUNITY_STATUS_PROCESS, Permission.MENU_SALES_ORGANIZATION],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/sales-organization-management/sales-organization-management.module')
          .then(m => m.SalesOrganizationManagementModule)
      },
      {
        path: 'management/data-exports',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.LISTVIEW_DOCUMENT_DOWNLOAD_SEARCH,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/listview-document-download/listview-document-download.module').then(m => m.ListviewDocumentDownloadModule)
      },
      {
        path: 'management/language-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.LANGUAGE_MANAGEMENT,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/language-management/language-management.module').then(m => m.LanguageManagementModule)
      },
      {
        path: 'management/user-guide',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.USER_GUIDE_LIST,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/user-guide-management/user-guide-management.module').then(m => m.UserGuideManagementModule)
      },
      {
        path: 'management/users',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.SYSTEM_USER_SEARCH,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/user-management/user-management.module').then(m => m.UserManagementModule)
      },
      {
        path: 'management/account-merge',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACCOUNT_MERGE_INSERT,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/account-merge-management/account-merge-management.module').then(m => m.AccountMergeManagementModule)
      },
      {
        path: 'management/dynamic-field-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: [
            Permission.DYNAMIC_FIELD_MANAGEMENT_ACTIVITY_PURPOSE,
            Permission.DYNAMIC_FIELD_MANAGEMENT_CUSTOMER_TYPE,
            Permission.DYNAMIC_FIELD_INSERT,
            Permission.DYNAMIC_FIELD_UPDATE
          ],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/dynamic-field-management/dynamic-field-management.module').then(m => m.DynamicFieldManagementModule)
      },
      {
        path: 'management/service-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.ACCESS_ALL,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/service-management/service-management.module').then(m => m.ServiceManagementModule)
      },
      {
        path: 'custom-report-table',
        canActivate: [userPermissionGuard],
        data: {
          permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./custom-report-table/custom-report-table.module').then(m => m.CustomReportTableModule)
      },
      {
        path: 'custom-dashboard',
        canActivate: [userPermissionGuard],
        data: {
          permission: [Permission.CUSTOM_DASHBOARD_LIST, Permission.CUSTOM_DASHBOARD_GET_URL],
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./custom-dashboard-list/custom-dashboard-list.module').then(m => m.CustomDashboardListModule)
      },
      {
        path: 'management/custom-dashboard-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.CUSTOM_DASHBOARD_SEARCH,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./custom-dashboard-management/custom-dashboard-management.module').then(m => m.CustomDashboardManagementModule)
      },
      {
        path: 'management/system-setting-management',
        canActivate: [userPermissionGuard],
        data: {
          permission: Permission.REDIS_FLUSH_DATABASES,
          breadcrumbs: AppBreadcrumbResolverService
        },
        loadChildren: () => import('./management/system-setting-management/system-setting-management.module').then(m => m.SystemSettingManagementModule)
      },
      {
        path: 'error-403',
        loadChildren: () => import('./errors/error-403/error-403.module').then(m => m.Error403Module)
      },
    ]
  }, { path: '**', redirectTo: '' }
];
