import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Response } from '../api.model';
import { FinetuneModel } from '@core/api';

@Injectable({
    providedIn: 'root'
})
export class FinetuneModelService {
    constructor(private http: HttpClient) { }
    getCurrentModel() {
        return this.http.post<Response<FinetuneModel>>(environment.apiUrl + '/api/FinetuneModel/GetCurrentModel', null);
    }
    syncModel(request: any) {
        return this.http.post<Response<FinetuneModel>>(environment.apiUrl + '/api/FinetuneModel/SyncModel', request);
    }

}
